@import '../scss/_tools/global';

.header {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  z-index: 100;

  &__logo {
    padding-left: 15px;

    &__img {
      width: 100px;
      line-height: 0;
    }
  }
  &__nav {
    margin: 0;
    padding: 0;
    font-family: $en_fonts;

    &__list {
      list-style: none;
      margin: 0;
      padding: 0;

      &__item {
        a {
          text-decoration: none;
        }
      }
    }
  }
}
@include mq('for-sp') {
  .header {
    &__button {
      button {
        appearance: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 15px;
        height: 60px;
        border: none;
        background-color: transparent;

        span {
          display: block;
          width: 30px;
          height: 4px;
          border-radius: 2px;
          background-color: $sub_color;
          transition: all 300ms ease-in;

          + span {
            margin-top: 4px;
          }
          &:nth-child(1) {
            background-color: $main_color;
          }
        }
        .header.is-open & {
          span {
            width: 16px;

            &:nth-child(1) {
              background-color: $color_red;
              transform: translate(-1.5px, 3px) rotate(45deg);
            }
            &:nth-child(2) {
              background-color: $color_green;
              transform: translate(1.5px, -5px) rotate(-45deg);
              margin-left: 14px;
            }
            &:nth-child(3) {
              background-color: $color_yellow;
              transform: translate(1.5px, 4px) rotate(45deg);
              margin-left: 14px;
            }
            &:nth-child(4) {
              background-color: $color_blue;
              transform: translate(-1.5px, -4px)  rotate(-45deg);
            }
          }
        }
      }
    }
    &__nav {
      position: fixed;
      left: 100%;
      top: 60px;
      display: flex;
      justify-content: flex-end;
      height: calc(100% - 60px);
      width: 100%;
      overflow: hidden;
      opacity: 0;
      transition: opacity 300ms ease;

      .header.is-open & {
        left: 0;
        opacity: 1;
      }
      .header:not(.is-open) & {
        transition: left 0s linear 300ms, opacity 300ms ease;
      }
      &__space {
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000, $alpha: .8);
        transition: width 300ms ease-in;

        .header.is-open & {
          width: 20%;
        }
      }
      &__list {
        overflow: hidden;
        width: 0;
        height: 100%;
        background-color: #fff;
        transition: width 300ms ease-in;

        .header.is-open & {
          width: 80%;
        }
        &__item {
          & + & {
            border-top: 1px solid #ccc;
          }
          &:last-child {
            border-bottom: 1px solid #ccc;
          }
          a {
            display: block;
            color: $sub_color;
            padding: 8px 20px;
            font-size: 25px;

            &:first-letter {
              color: $main_color;
            }
          }
        }
      }
    }
  }
}
@include mq('for-pc') {
  .header {
    &__nav {
      &__list {
        display: flex;
        align-items: center;

        &__item {
          a {
            display: block;
            padding: 15px 30px;
            font-size: 18px;
            color: $sub_color;
            transition: all 300ms;

            &:hover:first-letter,
            &.is-current:first-letter {
              color: $main_color;
            }
          }
        }
      }
    }
  }
}
