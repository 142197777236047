@import '../../components/scss/_tools/_global';

.works {
  &__page_title {
    background-image: url(../../assets/img/works/image01.jpg);
  }
  &__lead {
    text-align: center;
  }
  &__tags {
    display: flex;
    flex-wrap: wrap;
    margin: 2em 0 0;
    padding: 0;
    list-style: none;
    gap: 1em;

    &__item {
      $cnt: #{&}__cnt;

      display: flex;
      gap: 10px;
      align-items: center;
      padding: 5px 10px;
      border: 1px solid $main_color;
      border-radius: 10px;
      background-color: #fff;
      color: $main_color;
      cursor: pointer;
      user-select: none;

      #{$cnt} {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background-color: $main_color;
        color: #ccc;
        font-size: 14px;
      }
      &.is-selected {
        background-color: $main_color;
        color: #fff;

        #{$cnt} {
          background-color: #fff;
          color: $main_color;
        }
      }
    }
  }
  &__items {
    margin: 2.5em -0.5em -0.5em;
  }
  &__card {
    padding: 1em;
    border: 1px solid $main_color;
    border-radius: 20px 5px 20px 5px;
    background-color: #fff;
    transition: all 300ms;

    &__title {
      margin: 0 0 1em;
      padding-bottom: .5em;
      border-bottom: 2px solid $main_color;
      font-size: 20px;
      line-height: 1.4;
    }
    &__tags {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin: 1.5em 0 0;
      padding: 0;
      list-style: none;

      li {
        padding: 5px 10px;
        border-radius: 5px;
        background-color: $main_color;
        font-size: 14px;
        color: #fff;
      }
    }
  }
  .muuri {
    &-item {
      padding: .5em;
      width: 100%;
    }
  }
}

@include mq('for-pc') {
  .works {
    .muuri {
      &-item {
        width: 50%;
      }
    }
  }
}
