@import '../../../components/scss/_tools/global';

.skill {
  &__ttl {
    &--01 {
      &__en {
        &:first-letter {
          color: #0068B7;
        }
      }
      &__slash {
        &:after {
          background-color: #0068B7;
        }
      }
    }
  }
  &__lead {
    margin-bottom: 30px;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    dl {
      margin: 0;

      dt {
        font-size: 18px;
        font-weight: bold;
      }
      dd {
        margin-left: 1em;
      }
    }
  }
  &__notes {
    margin-top: 30px;
    font-size: .9rem;
  }
}
@include mq('for-pc') {
  .skill {
    &__list {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
@media (min-width: 900px) {
  .skill {
    &__list {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}
