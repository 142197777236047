.notfound {
  &__page_title {
    background-color: #fff;
  }
  &__conts {
    p {
      text-align: center;
    }
    &__contact {
      margin-top: 3em;
    }
  }
}
