//////////////////////////////////////////////////
// breakpoints
//////////////////////////////////////////////////

$breakpoints: (
  'for-sp': 'screen and (max-width: 767px)',
  'for-pc': '(min-width: 768px)'
) !default;

@mixin mq($breakpoint: 'for-sp') {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
