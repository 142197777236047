// app
#app {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 60px);
}

// contents
.c-content {
  margin: 0 20px;
  padding-top: 4em;
  padding-bottom: 4em;
}
@include mq('for-pc') {
  .c-content {
    margin: 0 auto;
    padding-top: 10em;
    padding-bottom: 10em;
    width: 80vw;
    max-width: 900px;
  }
}

// lower contents
.c-lower_content {
  position: relative;
  margin: 0 20px;
  padding-bottom: 4em;
  z-index: 2;

  &__section {
    padding-top: 3em;
  }
}
@include mq('for-pc') {
  .c-lower_content {
    margin: 0 auto;
    width: 80vw;
    max-width: 900px;
  }
}

// section
.c-sec__dep {
  &--01 {
    margin-top: 2.5em;
  }
}

@include mq('for-pc') {
  .c-sec__dep {
    &--01 {
      margin-top: 3.5em;
    }
  }
}

// title
.c-ttl {
  &__01 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .7em;
    margin: 0 0 2em;

    &__en {
      font-size: 24px;
    }
    &__jp {
      font-size: 16px;
    }
    &__slash {
      position: relative;
      display: block;
      width: 2px;
      height: 1.3em;

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 2px;
        transform: rotate(25deg);
      }
    }
  }
  &__02 {
    margin: 0 0 1em;
    text-align: center;
    font-size: 20px;
  }
  &__03 {
    position: relative;
    margin-bottom: 1em;
    padding-bottom: .5em;
    border-bottom: 1px solid rgba($color: #333, $alpha: .2);
    font-weight: bold;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      border: 3px solid transparent;
      border-right-color: rgba($color: #333, $alpha: .3);
      border-bottom-color: rgba($color: #333, $alpha: .3);
    }
  }
}
@include mq('for-pc') {
  .c-ttl {
    &__01 {
      gap: 2em;

      &__jp {
        font-size: 18px;
      }
      &__en {
        font-size: 32px;
      }
      &__slash {
        width: 4px;
        height: 1.8em;
      }
    }
    &__02 {
      font-size: 24px;
    }
  }
}

// button
.c-btn {
  &__01 {
    text-align: center;
    a {
      display: inline-block;
      padding: 1em 3em;
      background: #424379;
      border-radius: 4px;
      color: #FFF;
      font-weight: bold;
      text-decoration: none;
    }
  }
}

@include mq('for-pc') {
  .c-btn {
    &__01 {
      a {
        transition: opacity .3s ease;
        &:hover {
          opacity: .7;
        }
      }
    }
  }
}

// Form
.c-form {
  &__input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    display: block;
    padding: 8px 10px;
    width: 100%;
    border: 1px solid #ccc;
    font-family: 'Noto Serif JP', serif;
    font-size: 16px;
    color: #333;

    &:focus {
      outline: none;
      border: 1px solid #3498db;
    }
  }
  &__radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    position: relative;
    width: 16px;
    height: 16px;
    margin: 0 10px 0 0;
    visibility: hidden;

    &:before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 2px;
      background-color: #ccc;
      border-radius: 50%;
      visibility: visible;
    }
    &:after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      left: 3px;
      top: 5px;
      background-color: #3498db;
      border-radius: 50%;
      visibility: visible;
      transform: scale(0);
      transition: transform 300ms;
    }
    &:checked:after  {
      transform: scale(1);
    }
  }
  &__submit {
    padding: 10px 30px;
    background: #EFEFEF;
    border: 1px solid #CCC;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    appearance: none;
    font-size: 14px;
    transition: opacity 300ms;
    &:hover {
      border-color: #3498db;
    }
    &:disabled {
      cursor: unset;
      border-color: #CCC;
      opacity: .5;
    }
  }
  &__required {
    margin-left: 5px;
    color: #F00;
    vertical-align: sub;
  }
  &__error {
    margin-top: .5em;
    color: #F00;
    font-size: 14px;
  }
}

@include mq('for-pc') {
  .c-form {
    min-width: 30vw;
  }
}

// Page Title
.c-page_title {
  margin-bottom: 2em;
  padding: 20px 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 2;
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: #424379;
    opacity: .5;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__txt {
    margin: 0;
    font-size: 35px;
    text-align: center;
    text-shadow: 1px 1px 0 #FFF, -1px -1px 0 #FFF,
                -1px 1px 0 #FFF, 1px -1px 0 #FFF,
                0px 1px 0 #FFF, 0 -1px 0 #FFF,
                -1px 0 0 #FFF, 1px 0 0 #FFF;
    position: relative;
    z-index: 10;
    &__ja {
      display: block;
      font-size: 12px;
    }
  }
}
@include mq('for-pc') {
  .c-page_title {
    padding: 40px 0;
    &__txt {
      font-size: 40px;
    }
  }
}
