@import '../../../components/scss/_tools/global';

.flow {
  background-color: rgba($color: #333, $alpha: .05);

  &__ttl {
    &--01 {
      &__en {
        &:first-letter {
          color: #F8B62D;
        }
      }
      &__slash {
        &:after {
          background-color: #F8B62D;
        }
      }
    }
  }

  &__list {
    margin: 2em 0 0;
    padding: 0;
    list-style: none;
    counter-reset: flow_list;

    li {
      position: relative;
      counter-increment: flow_list;

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: calc(-1em - 10px);
        border-top: 10px solid #ddd;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        transform: translateX(-50%);
      }
      &:first-child {
        &:before {
          content: none;
        }
      }
      + li {
        margin-top: calc(2em + 10px);
      }
      dl {
        margin: 0;

        dt {
          &:before {
            content: counter(flow_list)'. ';
          }
        }
        dd {
          margin-left: 0;
        }
      }
    }
  }
}
@include mq('for-pc') {
  .flow {
    &__list {
      margin-top: 2em;

      li {
        &:before {
          top: calc(-1.5em - 10px);
        }
        + li {
          margin-top: calc(3em + 10px);
        }
      }
    }
  }
}
