.partner {
  &__page_title {
    background-image: url(../../assets/img/partner/image01.jpg);
  }
  &__conts {
    &__contact {
      margin-top: 3em;
    }
  }
}
