$animated: '.c-anime--animated';

.a {
  &-move {
    &--right, &--left {
      opacity: 0;
      animation: 1s forwards;
    }
    &--left {
      &#{$animated} {
        animation-name: move-left;
      }
    }
    &--right {
      &#{$animated} {
        animation-name: move-right;
      }
    }
  }
  &-zoom {
    &--out {
      opacity: 0;
      animation: 1s forwards;
    }
    &--out {
      &#{$animated} {
        animation-name: zoom-out;
      }
    }
  }
  &-fade {
    &--in {
      opacity: 0;
      animation: 1s forwards;
    }
    &--in {
      &#{$animated} {
        animation-name: fade-in;
      }
    }
  }
}

// Animation keyframes
@keyframes move-right {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes move-left {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes zoom-out {
  0% {
    opacity: 0;
    transform: scale(2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// animation-delay
$anime-delays: 300, 600;
@each $anime-delay in $anime-delays {
  *[data-anime-delay="#{$anime-delay}"] {
    animation-delay: #{$anime-delay}ms;
  }
}
