@import '../../../components/scss/_tools/global';

.strengths {
  &__ttl {
    &--01 {
      &__en {
        &:first-letter {
          color: #006934;
        }
      }
      &__slash {
        &:after {
          background-color: #006934;
        }
      }
    }
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      + li {
        margin-top: 2.5em;
      }
      dl {
        margin: 0;

        dd {
          margin-top: 1em;
          margin-left: 0;
        }
      }
    }
  }
}
