//////////////////////////////////////////////////
// 変数定義
//////////////////////////////////////////////////

// Fonts
$default_font_family: 'Noto Serif JP', serif;
$default_font_color: #333;
$default_font_size: 16px;
$default_line_height: 1.7;

$en_fonts: 'Roboto', sans-serif;

// Color
$main_color: #424379;
$sub_color: #B5B5B5;
$color_red: #c30d23;
$color_green: #006934;
$color_yellow: #F8B62D;
$color_blue: #0068B7;
