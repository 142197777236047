@import '../../../components/scss/_tools/global';

.about {
  background-color: rgba($color: #333, $alpha: .05);

  &__ttl {
    &--01 {
      &__en {
        &:first-letter {
          color: #c30d23;
        }
      }
      &__slash {
        &:after {
          background-color: #c30d23;
        }
      }
    }
  }
}
