.main_visual {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 60px);

  &__slide {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-attachment: fixed;
    opacity: 0;
    transition: opacity 1s;

    &__01 {
      background-image: url(../../assets/img/home/image01.jpg);
      background-position: 40% 50%;
    }

    &__02 {
      background-image: url(../../assets/img/home/image02.jpg);
      background-position: 30% 50%;
    }

    &__03 {
      background-image: url(../../assets/img/home/image03.jpg);
      background-position: 70% 50%;
    }
  }
  &__main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    gap: 4em;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #fff, $alpha: .75);
    z-index: 2;
  }
  &__logo {
    width: 65vw;
    max-height: 40vh;

    img {
      width: 100%;
      height: 100%;
    }
  }
  &__copy {
    line-height: 0;
    img {
      width: 70vw;
      max-width: 700px;
    }
  }
}

.messages {
  h2 {
    text-align: center;
  }
}

.fade {
  &-enter {
    &-active {
      opacity: 1;
    }
    &-done {
      opacity: 1;
    }
  }
}
