body {
  margin: 0;
  width: 100%;
  padding-top: 60px;
  font-family: $default_font_family;
  font-size: $default_font_size;
  line-height: $default_line_height;
  color: $default_font_color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*, *:before, *:after {
  box-sizing: border-box;
}

p {
  margin: 0;

  + p {
    margin-top: 1.7em;
  }
}

@include mq('for-sp') {
  .pc-only {
    display: none;
  }
}
@include mq('for-pc') {
  .sp-only {
    display: none;
  }
}
