@import '../../../components/scss/_tools/global';

.sns {
  background-color: rgba($color: #333, $alpha: .05);

  &__ttl {
    &--01 {
      &__en {
        &:first-letter {
          color: #424379;
        }
      }
      &__slash {
        &:after {
          background-color: #424379;
        }
      }
    }
  }
  &__lead {
    margin-bottom: 30px;
  }
}
@include mq('for-pc') {
  .sns {
    &__items {
      display: flex;
      justify-content: center;
    }
  }
}
@media (min-width: 900px) {
  .skill {
  }
}
