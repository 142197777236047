.contact {
  &__page_title {
    background-image: url(../../assets/img/contact/image01.jpg);
  }
  &__note {
    &__required {
      color: #F00;
      vertical-align: sub;
    }
  }
  &__list {
    &__contact_type {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
  &__submit {
    margin-top: 25px;
    text-align: center;
  }
  &__message {
    margin-top: 25px;
    padding: 10px;
    border-width: 2px;
    border-style: solid;
    border-radius: 4px;
    &.success {
      border-color: #398f14;
    }
    &.error {
      border-color: #F00;
      color: #F00;
    }
    a {
      color: #F00;
    }
  }
}
